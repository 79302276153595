*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input, textarea, button {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
}

textarea {
  resize: vertical;
}

button {
  cursor: pointer;
}

button:disabled {
  opacity: .5;
  pointer-events: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*# sourceMappingURL=index.8282a9dd.css.map */
